import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import the library
import VueNavigationBar from 'vue-navigation-bar'
import VueToast from 'vue-toast-notification'
import IdleVue from 'idle-vue'
import Print from 'vue-printjs'
import Schedule from 'vue-schedule'

// import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css'
// javascript import for when you're importing the css directly in your javascript
import 'vue-navigation-bar/dist/vue-navigation-bar.css'
import './assets/css/bootstrap/css/bootstrap.min.css'

Vue.component('vue-navigation-bar', VueNavigationBar)
Vue.use(Schedule)
Vue.use(VueToast)
Vue.use(Print)

Vue.config.productionTip = true
const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 600000, // 600 sec = 10 min
  startAtIdle: false,
  store
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="home">
    <TimeSheet :timeRange="timeRange" :weekDays="weekDays" :actvities="activities"/>
  </div>
</template>

<script>
// @ is an alias to /src
import TimeSheet from "../components/timesheet/TimeSheet";

export default {
  name: "Home",
  components: {
    TimeSheet,
  },
  data() {
    return {
      timeRange: ["09:00", "12:00"],
      weekDays: [
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sábado",
        "Domingo",
      ],
      activities: [
        [],
        [],
        [],
        [],
        [],
        [
          {
            dateStart: "10:00",
            dateEnd: "11:00",
            title: "Yaollin AMMT Online",
            detail: "Clase de Prueba",
          }
        ],
        []
      ],
    };
  },
};
</script>

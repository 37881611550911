<template>
  <section class="timetable_b">
    <div class="timetable">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="section_title_container">
              <div class="section_title mb-5">
                <h4>Clases</h4>
                <p>Contamos con las mejores clases en Yaollin</p>
              </div>
            </div>
            <div class="timetable_filtering">
              <ul
                class="d-flex flex-row align-items-start justify-content-center flex-wrap"
              >
                <li class="active item_filter_btn" data-filter="*">
                  Todas las Clases
                </li>
                <li class="item_filter_btn" data-filter=".formas">Formas</li>
                <li class="item_filter_btn" data-filter=".combates">
                  Combates
                </li>
                <li class="item_filter_btn" data-filter=".estiramiento">
                  Estiramiento
                </li>
                <li class="item_filter_btn" data-filter=".antecedentes">
                  Antecedentes
                </li>
                <li class="item_filter_btn" data-filter=".meditacion">
                  Meditación
                </li>
              </ul>
            </div>
            <div
              class="timetable_container d-flex flex-sm-row flex-column align-items-start justify-content-sm-between justify-content-start"
            >
              <!-- Lunes -->
              <div class="tt_day">
                <div class="tt_title">Lunes</div>
                <div class="tt_day_content grid">
                  <!-- Class -->
                  <div class="tt_class grid-item formas">
                    <div class="tt_class_title">Formas</div>
                    <div class="tt_class_instructor">José Luis</div>
                    <div class="tt_class_time">9:00</div>
                    <a href="" class="btn_details">Ver Clase</a>
                  </div>

                  <!-- Class -->
                  <div class="tt_class grid-item combates">
                    <div class="tt_class_title">Combates</div>
                    <div class="tt_class_instructor">José Luis</div>
                    <div class="tt_class_time">10:00</div>
                    <a href="" class="btn_details">Ver Clase</a>
                  </div>

                  <!-- Class -->
                  <div class="tt_class empty grid-item"></div>

                  <!-- Class -->
                  <div class="tt_class grid-item estiramiento">
                    <div class="tt_class_title">Estiramiento</div>
                    <div class="tt_class_instructor">José Luis</div>
                    <div class="tt_class_time">13:00</div>
                    <a href="" class="btn_details">Ver Clase</a>
                  </div>
                </div>
              </div>

              <!-- Martes -->
              <div class="tt_day">
                <div class="tt_title">Martes</div>
                <div class="tt_day_content grid">
                  <!-- Class -->
                  <div class="tt_class grid-item antecedentes">
                    <div class="tt_class_title">Antecedentes</div>
                    <div class="tt_class_instructor">José Luis</div>
                    <div class="tt_class_time">8:00</div>
                    <a href="" class="btn_details">Ver Clase</a>
                  </div>

                  <!-- Class -->
                  <div class="tt_class empty grid-item"></div>

                  <!-- Class -->
                  <div class="tt_class grid-item conceptos">
                    <div class="tt_class_title">Conceptos</div>
                    <div class="tt_class_instructor">José Luis</div>
                    <div class="tt_class_time">12:00</div>
                    <a href="" class="btn_details">Ver Clase</a>
                  </div>

                  <!-- Class -->
                  <div class="tt_class grid-item combates">
                    <div class="tt_class_title">Combates</div>
                    <div class="tt_class_instructor">José Luis</div>
                    <div class="tt_class_time">13:00</div>
                    <a href="" class="btn_details">Ver Clase</a>
                  </div>
                </div>
              </div>

              <!-- Miércoles -->
              <div class="tt_day">
                <div class="tt_title">Miércoles</div>
                <div class="tt_day_content grid">
                  <!-- Class -->
                  <div class="tt_class grid-item formas">
                    <div class="tt_class_title">Formas</div>
                    <div class="tt_class_instructor">José Luis</div>
                    <div class="tt_class_time">9:00</div>
                    <a href="" class="btn_details">Ver Clase</a>
                  </div>

                  <!-- Class -->
                  <div class="tt_class grid-item combates">
                    <div class="tt_class_title">Combates</div>
                    <div class="tt_class_instructor">José Luis</div>
                    <div class="tt_class_time">10:00</div>
                    <a href="" class="btn_details">Ver Clase</a>
                  </div>

                  <!-- Class -->
                  <div class="tt_class empty grid-item"></div>

                  <!-- Class -->
                  <div class="tt_class grid-item estiramiento">
                    <div class="tt_class_title">Estiramiento</div>
                    <div class="tt_class_instructor">José Luis</div>
                    <div class="tt_class_time">13:00</div>
                    <a href="" class="btn_details">Ver Clase</a>
                  </div>
                </div>
              </div>

              <!-- Jueves -->
              <div class="tt_day">
                <div class="tt_title">Jueves</div>
                <div class="tt_day_content grid">
                  <!-- Class -->
                  <div class="tt_class grid-item antecedentes">
                    <div class="tt_class_title">Antecedentes</div>
                    <div class="tt_class_instructor">José Luis</div>
                    <div class="tt_class_time">8:00</div>
                    <a href="" class="btn_details">Ver Clase</a>
                  </div>

                  <!-- Class -->
                  <div class="tt_class empty grid-item"></div>

                  <!-- Class -->
                  <div class="tt_class grid-item conceptos">
                    <div class="tt_class_title">Conceptos</div>
                    <div class="tt_class_instructor">José Luis</div>
                    <div class="tt_class_time">12:00</div>
                    <a href="" class="btn_details">Ver Clase</a>
                  </div>

                  <!-- Class -->
                  <div class="tt_class grid-item combates">
                    <div class="tt_class_title">Combates</div>
                    <div class="tt_class_instructor">José Luis</div>
                    <div class="tt_class_time">13:00</div>
                    <a href="" class="btn_details">Ver Clase</a>
                  </div>
                </div>
              </div>

              <!-- Viernes -->
              <div class="tt_day">
                <div class="tt_title">Viernes</div>
                <div class="tt_day_content grid">
                  <!-- Class -->
                  <div class="tt_class grid-item antecedentes">
                    <div class="tt_class_title">Antecedentes</div>
                    <div class="tt_class_instructor">José Luis</div>
                    <div class="tt_class_time">9:00</div>
                    <a href="" class="btn_details">Ver Clase</a>
                  </div>

                  <!-- Class -->
                  <div class="tt_class empty grid-item"></div>

                  <!-- Class -->
                  <div class="tt_class empty grid-item"></div>

                  <!-- Class -->
                  <div class="tt_class grid-item meditacion">
                    <div class="tt_class_title">Meditación</div>
                    <div class="tt_class_instructor">José Luis</div>
                    <div class="tt_class_time">13:00</div>
                    <a href="" class="btn_details">Ver Clase</a>
                  </div>
                </div>
              </div>

              <!-- Sábado -->
              <div class="tt_day">
                <div class="tt_title">Sábado</div>
                <div class="tt_day_content grid">
                  <!-- Class -->
                  <div class="tt_class empty grid-item"></div>

                  <!-- Class -->
                  <div class="tt_class empty grid-item"></div>

                  <!-- Class -->
                  <div class="tt_class empty grid-item"></div>

                  <!-- Class -->
                  <div class="tt_class empty grid-item"></div>
                </div>
              </div>

              <!-- Domingo -->
              <div class="tt_day">
                <div class="tt_title">Domingo</div>
                <div class="tt_day_content grid">
                  <!-- Class -->
                  <div class="tt_class empty grid-item"></div>

                  <!-- Class -->
                  <div class="tt_class empty grid-item"></div>
                  <!-- Class -->
                  <div class="tt_class empty grid-item"></div>

                  <!-- Class -->
                  <div class="tt_class empty grid-item"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TimeSheet",
  props: {
    timeRange: {
      type: Array,
      default: () => [],
    },
    weekDays: {
      type: Array,
      default: () => [],
    },
    activities: {
      type: Array,
      default: () => [[], []],
    },
  },
  created() {
    console.log(...this.activities);
  },
};
</script>
<template>
  <div id="app">
    <vue-navigation-bar :options="navbarOptions" />
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      navbarOptions: {
        elementId: "ammt-navbar",
        isUsingVueRouter: true,
        mobileBreakpoint: 992,
        brandImagePath: "./",
        brandImage: require("./assets/img/logo.png"),
        brandImageAltText: "ammt-logo",
        collapseButtonOpenColor: "#661c23",
        collapseButtonCloseColor: "#661c23",
        showBrandImageInMobilePopup: true,
        ariaLabelMainNav: "Main Navigation",
        tooltipAnimationType: "shift-away",
        menuOptionsLeft: [
          {
            type: "link",
            text: "Clases",
            arrowColor: "#659CC8",
            path: { name: "Clases" },
          },
          {
            type: "link",
            text: "Contacto",
            arrowColor: "#659CC8",
            path: { name: "Contacto" },
          },
          {
            type: "link",
            text: "Acerca",
            arrowColor: "#659CC8",
            path: { name: "About" },
          },
        ],
        menuOptionsRight: [
          {
            type: "button",
            text: "Login",
            path: { name: "Login" },
            class: "login_btn",
          },
          {
            type: "button",
            text: "Registro",
            path: { name: "Register" },
            class: "register_btn disabled",
          },
        ],
      },
    };
  },
};
</script>

<style src="./assets/css/style.css"></style>